/* External dependencies */
import React from 'react';

/* Local dependencies */
import ConsigneeContract from '../../../components/consignees/contract';
import Layout from '../../../components/layout';

export default function Contract() {
  return (
    <Layout>
      <ConsigneeContract />
    </Layout>
  );
}
