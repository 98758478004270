/* External dependencies */
import React from 'react';
import { useSelector } from 'react-redux';

/* Local dependencies */
import { RootState } from '../../../redux/store';
import { PageSpinner } from '../../spinner/Spinner';
import { ConsigneeContractState } from './redux/reducer';

export default function ConsigneeContract() {
  const {
    loading,
  } = useSelector((state: RootState): ConsigneeContractState => state.consigneeContract);

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <div>Consignee contract</div>
  );
}
